<template>
  <div class="">
    <div></div>

    <div>
      <div class="flex xs12 no-padding">
        <Preview
          type="book"
          :uuid="studentCredential.uuid"
          :slug="studentCredential.main_product.sku"
          :have_tools="studentCredential.main_product.isEditing"
          :dedication_text="currentDedication"
          :have_dedication="false"
          :character_id="studentCredential.main_product.character_id"
          :product_id="studentCredential.main_product.builder_id"
          :book_name="studentCredential.main_product.name"
          :book_brand="studentCredential.main_product.brand.name"
          :character_position_id="characterPositionId"
          :language="'pt'"
          :typeLanguage="'pt'"
          :typeRead="typeRead"
          :is_created="studentCredential.main_product.isEditing === true"
          :steps="breadcrumbs"
          :continueLabel="continueText"
          :custom_mes="studentCredential.main_product.custom_mes"
          :custom_numero="studentCredential.main_product.custom_numero"
          :gender="studentCredential.user.gender"
          @completed="completed"
          @changeRoute="changeRouteContinue"
          @changeUrl="changeUrl"
          @completedName="completedName"
          @completedBirthday="completedBirthday"
        />
      </div>
    </div>

    <vuestic-modal
      v-bind:large="true"
      ref="largeModal"
      :okText="'modal.confirm' | translate"
      :cancelText="'modal.cancel' | translate"
      :noButtons="true"
      :titleDisabled="false"
    >
      <div class="flex xs12 text-center">
        <h1>Parabéns, seu livro ficou incrível!</h1>
        <p>
          Logo ele chegará na sua escola pra você aproveitar muito a leitura!
        </p>

        <img
          :src="require('@/assets/images/congrats.png')"
          alt
          class="congrats"
        />
        <br /><br />

        <div class="flex xs12 text-center footer-modal no-padding-right">
          <router-link
            :to="{ name: 'login-child' }"
            class="btn btn-primary cancel"
          >
            JÁ TERMINOU? ENCERRAR SESSÃO
          </router-link>

          <button @click="closeFinish()" class="btn right">
            CONTINUAR LENDO
          </button>
          <br />
        </div>
      </div>
    </vuestic-modal>

    <vuestic-modal
      :show.sync="show"
      ref="modalPortability"
      v-bind:medium="true"
      v-bind:large="true"
      :okText="'modal.confirm' | translate"
      :cancelText="'modal.cancel' | translate"
      :noButtons="true"
      :cancelDisabled="true"
      :closeIconShown="true"
      class="modal-avatar-list"
    >
      <span slot="title">Editar livro</span>
      <div class="va-row">
        <div class="flex md8 xs12 modal-text no-padding">
          <!-- <h1></h1> -->

          <div>
            O avatar da criança
            <b>{{ studentCredential.user.custom_name }}</b> foi alterado.

            <br /><br />
            A atualização é somente para esse ou<br />
            para todos os os livros da criança?
          </div>
        </div>

        <div class="flex md4 xs12 modal-avatar">
          <div v-if="studentCredential.main_product.customization">
            <img
              v-for="customization in studentCredential.main_product
                .customization"
              :key="customization.categoryId"
              :src="customization.optionAsset"
              :style="[
                {
                  zIndex:
                    customization.optionZIndex != null
                      ? customization.optionZIndex
                      : customization.categoryZIndex,
                },
              ]"
            />
          </div>

          <div v-else>
            <img
              width="127"
              :src="
                'https://builder.dentrodahistoria.com.br/api/builder/getcharacter/' +
                studentCredential.uuid +
                '?page=1&w=200&v=0'
              "
              alt="Dentro Educa"
              class
            />
          </div>
        </div>
        <div class="clearfix"></div>
      </div>

      <hr />

      <div class="va-row text-right action-area">
        <div class="flex md12 text-right no-padding">
          <button class="btn cancel" @click="setPortability(true)">
            ATUALIZAR TODOS
          </button>
          <button class="btn btn-right" @click="setPortability(false)">
            ATUALIZAR ESSE
          </button>
        </div>
      </div>
    </vuestic-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import turn from "services/turn.min";
import { months, maxAgeAllowed, monthsHelper } from "./utils.js";

const axios = require("axios");

export default {
  name: "PreviewStudent",
  components: {},
  props: {
    /**
     * Indica se o botão esta desabilitado ou não
     */
    // product: {
    // 	type: Object,
    // 	required: false,
    // },
    isPriting: {
      type: Boolean,
      required: false,
      default: true,
    },

    // haveDedication: {
    // 	type: Boolean,
    // 	required: false,
    // 	default: false,
    // },

    typeRead: {
      type: Boolean,
      required: false,
      default: false,
    },

    triggerTrade: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    ...mapGetters({
      studentCredential: "studentCredential",
      teacherCredential: "teacherCredential",
      // ...
    }),
  },

  data() {
    return {
      hasMouse: true,
      pages: [],
      counter: 0,
      pageNum: 36,

      isCover: 0,
      currentPage: 3,
      loadedPages: 3,

      numberPositions: 0,
      mobilePagePosition: 0,

      isMobile: false,
      isLoaded: false,

      isPagesNumLoaded: false,

      loadedMobile: false,
      loadedDesktop: false,

      mobileStopped: true,
      isLoading: true,
      custom_character: null,

      productSelected: null,

      availableLayouts: [],

      currentLayout: 0,
      firstBookSelected: false,

      callingLayout: false,
      callingTestimonial: false,
      callingName: false,

      currentCharacterName: "",

      modalFirstOpen: true,

      currentName: "",
      currentDedication: "",

      have_pet: false,
      listPet: [],
      currentPetName: "",
      currentPet: [],
      currentPetColor: "",

      show: false,

      breadcrumbs: [
        {
          path: "grade",
          value: "Voltar",
        },
      ],

      //continueMethod: null,
      continueText: "PRONTINHO? SALVAR O LIVRO",
      characterPositionId: null,
      // name: '',
      // testimonial: '',
    };
  },

  mounted() {
    const self = this;

    // Breadcrumb
    if (
      self.studentCredential.isTeacher == false &&
      self.studentCredential.main_product.have_tools == false
    ) {
      self.breadcrumbs[0].path = "student-step-2";
    } else if (self.studentCredential.main_product.isEditing == true) {
      self.breadcrumbs[0].path = "student-step-2";
    } else if (!self.studentCredential.main_product.isEditing) {
      self.breadcrumbs[0].path = "grade";
    }

    // Dedicatória
    if (self.studentCredential.custom_character) {
      self.currentName =
        self.studentCredential.custom_character.user_data.properties.nome.value;
      self.currentDedication = self.studentCredential.dedication;
    }

    if (
      self.studentCredential.dedication &&
      self.studentCredential.dedication.legnth > 0
    ) {
      currentDedication = " ";
    }

    // Fallback de produto (nem lembro pq)
    if (self.product !== undefined) {
      self.productSelected = self.product;
      self.$store.commit("SAVE_PREVIEW_BOOK", this.product);
    } else {
      self.productSelected = self.studentCredential.preview;
    }

    // TEXTO DE CTA
    if (!self.studentCredential.isTeacher) {
      self.continueText = "SALVAR MEU LIVRO";
    }

    if (self.studentCredential.main_product.isEditing === false) {
      self.continueText = "VOLTAR";
    }

    // Mooney trazer character position
    if (self.studentCredential.main_product.brand.name.includes("Mooney")) {
      self.characterPositionId = 1196;
    }

    if (
      self.studentCredential.main_product.content_title.includes("bichinhos ")
    ) {
      self.characterPositionId = 908;
    }

    this.custom_character = this.studentCredential.custom_character;
  },

  updated() {
    const self = this;

    if (!self.isMobile & self.loadedDesktop) {
      self.nextTicker(self);
    }

    this.$nextTick(() => {
      if (
        self.isMobile &&
        self.loadedMobile &&
        document.getElementById("page-" + (self.loadedPages - 1))
      ) {
        self.mobilePagePosition =
          document.getElementById("page-" + (self.loadedPages - 1)).offsetLeft -
          50;
        document
          .getElementById("list-mobile")
          .addEventListener("scroll", self.handleScroll);
      }
    });
  },

  methods: {
    changeRouteContinue(payload) {
      const self = this;

      console.log(payload);

      if (payload.path) {
        self.$router.push({ name: payload.path });
      }
    },

    async setPortability(triggerTrade) {
      const self = this;

      self.triggerTrade = triggerTrade;
      var userItem = await self.saveUser(self.studentCredential.uuid);
      console.log(userItem);
      self.$router.push({ name: "grade" });
    },

    openModalPortabiliy() {
      this.$refs.modalPortability.open();
    },

    async showFinishRoute() {
      const self = this;

      //this.$refs.modalPortability.open();

      var userItem = await self.saveUser(self.studentCredential.uuid);
      console.log(userItem);
      self.$router.push({ name: "grade" });
    },

    async showFinish() {
      const self = this;

      self.triggerTrade = true;
      var userItem = await self.saveUser(self.studentCredential.uuid);
      console.log(userItem);

      self.$refs.largeModal.open();
    },

    changeUrl(payload) {
      const self = this;

      //console.log(payload);
      // VOLTAR - PROFESSOR
      if (
        self.studentCredential.isTeacher &&
        self.studentCredential.main_product.isEditing === false
      ) {
        //self.continueText = "VOLTAR";
        self.$router.push({ name: "grade" });
        return;
      }

      if (self.studentCredential.main_product.have_tools != false) {
        // Aluno default
        // CTA - SALVAR MEU LIVRO
        if (!self.studentCredential.isTeacher) {
          self.showFinish();
          return;
        }

        // Mooney (CASO DE PROFESSOR)
        // CTA - PRONTINHO? SALVAR O LIVRO
        if (
          self.studentCredential.isTeacher === true &&
          self.studentCredential.main_product &&
          self.studentCredential.main_product.brand &&
          self.studentCredential.main_product.brand.name &&
          self.studentCredential.main_product.brand.name.includes("Mooney")
        ) {
          self.showFinishRoute();
          return;
        }

        // É portabilidade mas n tem modal, ou seja, primeira personalização
        // CTA - PRONTINHO? SALVAR O LIVRO
        if (
          !self.studentCredential.old_uuid &&
          self.studentCredential.isTeacher &&
          self.studentCredential.grade.has_trade == true &&
          self.studentCredential.multiple_products == false
        ) {
          self.setPortability(true);
          return;
        }

        // É portabilidade com modal, HAS_TRADE
        if (
          self.studentCredential.old_uuid &&
          self.studentCredential.isTeacher &&
          self.studentCredential.grade.has_trade == true &&
          self.studentCredential.multiple_products == false
        ) {
          self.openModalPortabiliy();
          return;
        }

        // Ainda não sei 1
        if (
          self.studentCredential.isTeacher === true &&
          (Array.isArray(self.studentCredential.multiple_products) ||
            self.studentCredential.multiple_products != false) &&
          self.studentCredential.grade.has_trade == false &&
          self.studentCredential.main_product.brand.name.includes("Mooney") ==
            false
        ) {
          self.setPortability(false);
          return;
        }

        // If aniversário
        if (
          self.studentCredential.isTeacher === true &&
          self.studentCredential.grade.has_trade == false &&
          self.studentCredential.multiple_products == false &&
          self.studentCredential.settings.has_multiple_products == false &&
          self.studentCredential.main_product.brand.name.includes("Mooney") ==
            false
        ) {
          self.setPortability(false);
          return;
        }

        //
      }
    },

    completed(payload) {
      const self = this;

      self.$store.commit("UPDATE_STUDENT_UUID", payload.uuid);
    },

    completedName(payload) {
      const self = this;

      self.$store.commit("UPDATE_STUDENT_CREDENTIAL_CUSTOM_NAME", payload.name);
    },

    completedBirthday(payload) {
      const self = this;

      self.$store.commit(
        "UPDATE_MAIN_PRODUCT_CUSTOM_NUMERO",
        payload.dia.toString()
      );

      self.$store.commit(
        "UPDATE_MAIN_PRODUCT_CUSTOM_MES",
        payload.mes.toString()
      );
    },

    closeFinish() {
      if (this.$refs.largeModal) {
        this.$refs.largeModal.close();
      }

      if (this.$refs.gradeModal) {
        this.$refs.gradeModal.close();
      }
    },

    openModalWithLayouts() {
      const self = this;

      self.$nextTick(() => {
        setTimeout(function () {
          if (self.availableLayouts.length > 1) {
            self.showModalLayout();
          }
        }, 3000);
      });
    },

    setCurrentLayout(layoutId) {
      this.currentLayout = layoutId;
      this.firstBookSelected = true;
    },

    async saveUser(uuid) {
      const self = this;

      let config = {
        headers: {
          Authorization: "Bearer " + this.studentCredential.token,
        },
      };

      let request = {
        uuid: uuid,

        gradeId: this.studentCredential.gradeId,
        productId: self.studentCredential.main_product.id,
        triggerTrade: self.triggerTrade,
        isUpdate: self.studentCredential.isUpdate,
        name: self.studentCredential.user.custom_name,
        type_id:
          self.studentCredential.main_product.types[
            self.studentCredential.user.gender
          ],
      };

      if (self.studentCredential.old_uuid) {
        request.old_uuid = self.studentCredential.old_uuid;
      }

      var finalData = await axios
        .post(process.env.VUE_APP_ROOT_API + "/students/save", request, config)
        .then(function (response) {
          self.$store.commit("UPDATE_STUDENT_OLD_UUID", null);
          console.log("Salvou a portabilidade do usuário");
          return response;
        })
        .catch(function (error) {
          console.log(error);

          alert(error.response.data.message);
        });
      console.log("Reposta do await da save user");
      console.log(finalData);

      return finalData;
    },

    async saveUserUpdate(uuid, grade_id) {
      const self = this;

      let config = {
        headers: {
          Authorization: "Bearer " + this.studentCredential.token,
        },
      };

      let request = {
        uuid: uuid,

        gradeId: grade_id,
        productId: self.studentCredential.main_product.id,
        name: self.currentName,
        type_id: self.studentCredential.custom_character.type_id,
        triggerTrade: false,
        isUpdate: self.studentCredential.isUpdate,
        layout_id:
          self.studentCredential.subscription.product.layouts[
            self.currentLayout
          ].id,
        name: self.studentCredential.user.custom_name,
        type_id:
          self.studentCredential.main_product.types[
            self.studentCredential.user.gender
          ],
      };

      if (self.studentCredential.old_uuid) {
        request.old_uuid = self.studentCredential.old_uuid;
      }

      var finalData = await axios
        .post(process.env.VUE_APP_ROOT_API + "/students/save", request, config)
        .then(function (response) {
          //self.$router.push({ name: 'preview', params: { product: product, isTeacher: self.isTeacher, grade: self.grade } })
          self.$store.commit("UPDATE_STUDENT_OLD_UUID", null);
          console.log("salvou usuário");
          return response;
        })
        .catch(function (error) {
          // console.log(error)
          this.errors.push(error.response.data.message);
        });

      return finalData;
    },

    async submitName() {
      const self = this;
      var changeProps = null;
      self.callingName = true;

      var isValidate = false;
      isValidate = await self.$validator.validateAll().then((result) => {
        return result;
      });

      if (isValidate) {
        self.$store.commit("UPDATE_STUDENT_NAME", self.currentName);

        changeProps = await axios
          .post(
            "https://builder.dentrodahistoria.com.br/api/customs_characters/changePropertiesDynamic",
            { custom_character: self.custom_character }
          )
          .then(async function (response) {
            self.$store.commit(
              "UPDATE_STUDENT_UUID",
              response.data.custom.uuid
            );
            self.productSelected.builderCharacterId = response.data.custom.uuid;
            var user = await self.saveUserUpdate(
              response.data.custom.uuid,
              self.studentCredential.gradeId
            );

            self.bookDestroy();
            self.initMounted();
            self.closeModal();

            self.callingName = false;

            return response;
          })
          .catch(function (error) {
            let fieldError = {
              field: "error",
              msg: error.response.data.message,
              rule: "error", // optional
            };
            self.$validator.errors.add(fieldError);
            self.callingName = false;
          });
      }

      return changeProps;
    },

    async changeLayout() {
      const self = this;
      self.callingLayout = true;
      var changeLayoutReponse = null;

      var isValidate = false;
      isValidate = await self.$validator.validateAll().then((result) => {
        return result;
      });

      if (isValidate) {
        //self.$validator.validateAll().then((result) => {
        // if (result) {
        self.$store.commit(
          "UPDATE_STUDENT_LAYOUT_ID",
          self.studentCredential.subscription.product.layouts[
            self.currentLayout
          ].id
        );

        changeLayoutReponse = await axios
          .post(
            "https://builder.dentrodahistoria.com.br/api/customs_characters/",
            { custom_character: self.custom_character }
          )
          .then(async function (response) {
            self.$store.commit(
              "UPDATE_STUDENT_UUID",
              response.data.custom.uuid
            );
            self.productSelected.builderCharacterId = response.data.custom.uuid;
            var user = await self.saveUserUpdate(
              response.data.custom.uuid,
              self.studentCredential.gradeId
            );

            self.bookDestroy();
            self.initMounted();
            self.closeModal();
            self.callingLayout = false;
            self.modalFirstOpen = false;

            return response;
          })
          .catch(function (error) {
            let fieldError = {
              field: "error",
              msg: error.response.data.message,
              rule: "error", // optional
            };
            self.$validator.errors.add(fieldError);
            self.callingLayout = false;
          });
      }

      //});
    },

    async changePet() {
      const self = this;
      self.callingLayout = true;

      var isValidate = false;
      isValidate = await self.$validator.validateAll().then((result) => {
        return result;
      });

      if (isValidate) {
        if (
          self.studentCredential.custom_character.user_data.properties[
            "nome-pet"
          ]
        ) {
          var position = 0;
          self.studentCredential.custom_character.categories.forEach((e) => {
            if (e.parent_id === 256) {
              var item = {
                id: self.currentPetColor.id,
                parent_id: self.currentPet.parent_id,
                index: position,
              };
              self.$store.commit("UPDATE_STUDENT_PET", item);
            }
            position++;
          });
        }

        axios
          .post(
            "https://builder.dentrodahistoria.com.br/api/customs_characters/",
            { custom_character: self.custom_character }
          )
          .then(async function (response) {
            self.$store.commit(
              "UPDATE_STUDENT_UUID",
              response.data.custom.uuid
            );
            self.productSelected.builderCharacterId = response.data.custom.uuid;
            var user = await self.saveUserUpdate(
              response.data.custom.uuid,
              self.studentCredential.gradeId
            );

            // self.bookDestroy()
            // self.initMounted()
            // self.closeModal()
            // self.callingLayout = false
            // self.modalFirstOpen = false

            self.changePetName();
          })
          .catch(function (error) {
            let fieldError = {
              field: "error",
              msg: error.response.data.message,
              rule: "error", // optional
            };
            self.$validator.errors.add(fieldError);
            self.callingLayout = false;
          });
      }
    },

    async changePetName() {
      const self = this;

      self.callingName = true;
      var isValidate = false;
      isValidate = await self.$validator.validateAll().then((result) => {
        return result;
      });

      if (isValidate) {
        self.$store.commit("UPDATE_STUDENT_PET_NAME", self.currentPetName);

        axios
          .post(
            "https://builder.dentrodahistoria.com.br/api/customs_characters/changePropertiesDynamic",
            { custom_character: self.custom_character }
          )
          .then(async function (response) {
            self.$store.commit(
              "UPDATE_STUDENT_UUID",
              response.data.custom.uuid
            );
            self.productSelected.builderCharacterId = response.data.custom.uuid;
            await self.saveUserUpdate(
              response.data.custom.uuid,
              self.studentCredential.gradeId
            );

            self.bookDestroy();
            self.initMounted();
            self.closeModal();

            self.callingName = false;
          })
          .catch(function (error) {
            let fieldError = {
              field: "error",
              msg: error.response.data.message,
              rule: "error", // optional
            };
            self.$validator.errors.add(fieldError);
            self.callingName = false;
          });
      }
    },

    createInitialPages() {
      //var d = new Date();
      var n = Math.floor(Math.random() * 1000);

      var InitialPages =
        "<div class='hard'><img src='https://builder.dentrodahistoria.com.br/api/builder/getpreview/" +
        this.productSelected.builderCharacterId +
        "?page=1&w=533&v=0&update=" +
        n +
        "&noCache=true' alt=''></div>";
      InitialPages +=
        "<div class='hard front-side'><img src='https://builder.dentrodahistoria.com.br/api/builder/getpreview/" +
        this.productSelected.builderCharacterId +
        "?page=2&w=533&v=0&update=" +
        n +
        "&noCache=true' alt=''></div>";
      InitialPages +=
        "<div class='hard'><img src='https://builder.dentrodahistoria.com.br/api/builder/getpreview/" +
        this.productSelected.builderCharacterId +
        "?page=3&w=533&v=0&update=" +
        n +
        "&noCache=true' alt=''></div>";

      InitialPages +=
        "<div class='hard back-side'><img src='https://builder.dentrodahistoria.com.br/api/builder/getpreview/" +
        this.productSelected.builderCharacterId +
        "?page=" +
        (this.pageNum - 1) +
        "&w=533&v=0&update=" +
        n +
        "&noCache=true' alt=''></div>";
      InitialPages +=
        "<div class='hard'><img src='https://builder.dentrodahistoria.com.br/api/builder/getpreview/" +
        this.productSelected.builderCharacterId +
        "?page=" +
        this.pageNum +
        "&w=533&v=0&update=" +
        n +
        "&noCache=true' alt=''></div>";

      $("#flipbook").html(InitialPages);
    },

    bookDestroy() {
      const self = this;

      (self.pages = []), (self.counter = 0);
      self.pageNum = 36;

      self.isCover = 0;
      self.currentPage = 3;
      self.loadedPages = 3;

      self.numberPositions = 0;
      self.mobilePagePosition = 0;

      self.isMobile = false;
      self.isLoaded = false;

      self.isPagesNumLoaded = false;

      self.loadedMobile = false;
      self.loadedDesktop = false;

      self.mobileStopped = true;
      self.isLoading = true;

      if ($("#flipbook").length > 0 && $("#flipbook").turn("is")) {
        // Create a new flipbook
        $("#flipbook").turn("destroy");
      } else {
        //$(".mobile-shelf").html("");
      }
    },

    initMounted() {
      const self = this;

      axios
        .get(
          "https://builder.dentrodahistoria.com.br/api/products/info/" +
            self.studentCredential.main_product.builder_id
        )
        .then(function (response) {
          self.pageNum = response.data.product.pages;
          self.isPagesNumLoaded = true;
          self.availableLayouts = response.data.product.layout;

          self.currentCharacterName =
            self.studentCredential.subscription.product.layouts[
              self.currentLayout
            ].label.toString();

          // Verificações
          self.isMobile = self.detectSize();

          if (self.isMobile) {
            self.loadedDesktop = false;
            self.loadedMobile = true;

            self.isLoading = false;

            setTimeout(function () {
              self.$nextTick(() => {
                self.initBookMobile();
              });
            }, 2000);
          } else {
            self.loadedDesktop = true;
            self.loadedMobile = false;

            self.createInitialPages();
            setTimeout(function () {
              self.isLoading = false;

              self.$nextTick(() => {
                self.initBook();
              });

              self.$nextTick(() => {
                if (!self.isMobile & self.loadedDesktop) {
                  self.nextTicker(self);
                }
              });
            }, 2000);
          }
        })

        .catch(function (error) {
          console.log(error);
        });
    },

    initResize() {
      const self = this;

      window.addEventListener("resize", function (e) {
        // Detectar página
        self.isMobile = self.detectSize();

        if (self.isMobile === true && self.loadedMobile === false) {
          if (self.mobilePagePosition === 0) {
            self.initBookMobile();
          }

          self.loadedDesktop = false;
          self.loadedMobile = true;
        } else {
          if (self.isMobile === false && self.loadedDesktop === true) {
            var size = self.resize();
            $("#flipbook").turn("size", size.width, size.height);
            $("#flipbook").turn("resize");
          } else if (self.isMobile === false && self.loadedDesktop === false) {
            self.loadedDesktop = true;
            self.loadedMobile = false;

            self.currentPage = 3;
            self.loadedPages = 3;
            self.numberPositions = 0;

            self.$nextTick(() => {
              self.pages = [];
              self.mobilePagePosition = 0;

              self.initBook();
            });
          }
        }
      });
    },

    closeModal() {
      if (this.$refs.largeModal) {
        this.$refs.largeModal.close();
      }

      if (this.$refs.gradeModal) {
        this.$refs.gradeModal.close();
      }

      if (this.$refs.nameModal) {
        this.$refs.nameModal.close();
      }

      if (this.$refs.testimonialModal) {
        this.$refs.testimonialModal.close();
      }

      if (this.$refs.layoutModal) {
        this.$refs.layoutModal.close();
      }

      if (this.$refs.largeModalPet) {
        this.$refs.largeModalPet.close();
      }
    },

    // showSchool () {
    //   // this.schoolModalType = type
    //   // this.schoolSelected = this.jsonCopy(school);
    //   this.$refs.largeModal.open()
    // },

    showModalName() {
      this.$refs.nameModal.open();
    },

    showModalTestimonial() {
      this.$refs.testimonialModal.open();
    },

    showModalLayout() {
      this.$refs.layoutModal.open();
    },

    showModalPet() {
      const self = this;

      axios
        .get(
          "https://builder.dentrodahistoria.com.br/api/characters/46/categories/assets/treelist?character_type_id=67&product_id=111&character_position_id=377"
        )
        .then(function (response) {
          console.log(response);
          self.listPet =
            response.data.character_categories_assets[0].categories[5];
        })

        .catch(function (error) {
          console.log(error);
        });

      this.$refs.largeModalPet.open();
    },

    setPet(selectedPet) {
      this.currentPet = selectedPet;
      this.setColor(this.currentPet.assets.colors[0]);
    },

    setColor(selectedColor) {
      this.currentPetColor = selectedColor;
    },

    nextTicker(self) {
      $("#flipbook").bind("turning", function (event, page, view) {
        self.currentPage = page;

        if (self.currentPage >= self.loadedPages - 2) {
          self.moreBooks();
        }

        if (self.currentPage === 1) {
          self.numberPositions = 0;
          self.isCover =
            "-" + $(".page-wrapper[page=" + self.currentPage + "]").width();
        } else if (self.currentPage === self.pageNum) {
          self.isCover = $(
            ".page-wrapper[page=" + self.currentPage + "]"
          ).width();
        } else {
          self.isCover = 0;

          if (self.currentPage % 2 == 0) {
            self.numberPositions = self.currentPage * 30;
          } else {
            self.numberPositions = (self.currentPage - 1) * 30;
          }
        }

        $(".page").unbind("click");
        $(".page").click(function (event) {
          event.preventDefault();
          event.stopPropagation();
          event.stopImmediatePropagation();

          var pagina = $(this).parent().parent().attr("page");

          if (pagina % 2 == 0) {
            $("#flipbook").turn("previous");
          } else {
            $("#flipbook").turn("next");
          }
        });

        // Exibir a abertura de página
        // if (page === 2) {
        //   setTimeout(function () {
        //     $('#flipbook').turn('peel', 'tr')
        //   }, 800)
        // }
      });
    },

    // Desk events
    initBook() {
      $("#flipbook").turn({
        width: "100vh",
        height: "calc(75vh - 50px)",
        autoCenter: true,
        gradients: true,
        acceleration: true,
        duration: 800,
        elevation: 150,
        options: { turnCorners: "null" },
      });

      this.isCover = "-" + $(".page-wrapper[page=1]").width();

      $("#flipbook").bind("start", function (event, pageObject, corner) {
        if (
          corner == "tl" ||
          corner == "tr" ||
          corner == "bl" ||
          corner == "br"
        ) {
          event.preventDefault();
        }
      });

      // Habilitar passagem via o dedo
      $(document).keydown(function (e) {
        var previous = 37;
        var next = 39;
        switch (e.keyCode) {
          case previous:
            jQuery("#flipbook").turn("previous");
            break;
          case next:
            jQuery("#flipbook").turn("next");
            break;
        }
      });

      // Iniciar click nas capas (80% do tamanho)
      $(".page").click(function (event) {
        event.preventDefault();
        event.stopPropagation();
        event.stopImmediatePropagation();

        var pagina = $(this).parent().parent().attr("page");

        if (pagina % 2 == 0) {
          $("#flipbook").turn("previous");
        } else {
          $("#flipbook").turn("next");
        }
      });

      // Verificar páginas carregadas e nunca carregas 2 últimas
      for (var i = this.loadedPages; i < this.pageNum - 2; i++) {
        var pageCount = $("#flipbook").turn("pages") + 1;

        var element = $(
          '<div><img class="img-book" width="100%" src="https://dka04237waacy.cloudfront.net/api/builder/getplaceholder/' +
            this.studentCredential.subscription.product.builderProductId +
            "?page=" +
            i +
            '&w=100&pw=0" alt=""><div class="loading-bg"><div class="loading book-loading"></div></div> </div>'
        );

        $("#flipbook").turn("addPage", element);
        $("#flipbook").turn("pages", pageCount); // Sets the total # of pages
      }

      $("#flipbook").turn("next");
      setTimeout(function () {
        $("#flipbook").turn("previous");
      }, 1000);
    },

    moreBooks() {
      for (var i = this.loadedPages + 1; i <= this.loadedPages + 4; i++) {
        this.addPage(i, $("#flipbook"));
      }
      this.loadedPages = this.loadedPages + 4;
    },

    addPage(page, book) {
      if (book.turn("hasPage", page) && page <= this.pageNum - 2) {
        let classHard = "";

        var element = $("<div />").html(
          '<div><img class="img-book" width="100%" src="https://dka04237waacy.cloudfront.net/api/builder/getplaceholder/' +
            this.studentCredential.subscription.product.builderProductId +
            "?page=" +
            page +
            '&w=100&pw=0&rnd=" alt=""><div class="loading-bg"><div class="loading book-loading"></div></div> </div>'
        );
        book.turn("addPage", element, page);

        var url =
          "https://builder.dentrodahistoria.com.br/api/builder/getpreview/" +
          this.productSelected.builderCharacterId +
          "?page=" +
          page +
          "&w=533&v=0&random" +
          Math.floor(Math.random() * 3);

        axios
          .get(url, {
            responseType: "arraybuffer",
          })
          .then((response) => {
            const cover = new Buffer.from(response.data, "binary").toString(
              "base64"
            );
            element.html(
              "<img width='100%' src='data:image/png;base64," + cover + "'>"
            );
          });
      }
    },

    toTheLeft() {
      $("#flipbook").turn("previous");
    },

    toTheRight() {
      $("#flipbook").turn("next");
    },

    detectSize() {
      var menuStatus = false;
      if (window.innerWidth < 990) {
        menuStatus = true;
      }
      return menuStatus;
    },

    resize() {
      var barSize = $(".ddh-header").height() + 40;
      var controlSize = $(".controls").height() + 20;

      var width = "100vh";
      var height = "calc(75vh - 50px)";

      return {
        width: width,
        height: height,
      };
    },

    // Mobile events
    initBookMobile() {
      this.loadedPages = 1;

      for (var i = 0; i < this.pageNum; i++) {
        let page = {
          url:
            "https://dka04237waacy.cloudfront.net/api/builder/getplaceholder/" +
            this.studentCredential.subscription.product.builderProductId +
            "?page=" +
            i +
            "&w=100&pw=0",
          isLoaded: false,
        };
        this.pages.push(page);
      }

      this.moreBooksMobile();
    },

    moreBooksMobile() {
      const self = this;

      for (
        var i = this.loadedPages;
        i <= this.loadedPages + 4 && i <= this.pageNum;
        i++
      ) {
        let x = i;
        var url =
          "https://builder.dentrodahistoria.com.br/api/builder/getpreview/" +
          this.productSelected.builderCharacterId +
          "?page=" +
          i +
          "&w=312&v=0";

        axios
          .get(url, {
            responseType: "arraybuffer",
          })
          .then((response) => {
            var responseok = response;

            const cover = new Buffer.from(response.data, "binary").toString(
              "base64"
            );
            // element.html("<img width='100%' src='data:image/png;base64," + cover + "'>")
            let page = {
              url: "data:image/png;base64," + cover,
              isLoaded: true,
            };

            self.pages.splice(x - 1, 1, page);
          });
      }

      this.loadedPages = this.loadedPages + 4;
      if (document.getElementById("page-" + this.loadedPages - 1)) {
        this.mobilePagePosition =
          document.getElementById("page-" + this.loadedPages).offsetLeft - 50;
      }
    },

    handleScroll(event) {
      if (
        document.getElementById("list-mobile").scrollLeft >=
        this.mobilePagePosition
      ) {
        this.moreBooksMobile();
      }
      this.mobileStopped = false;
    },
  },
};
</script>

<style lang="scss" scoped>
// @import "../../assets/turn/jquery.ui.css";
// @import "../../assets/turn/jquery.ui.html4.css";
// @import '../../assets/turn/steve-jobs-html4.css';
// @import "../../assets/turn/steve-jobs.css";
// ::v-deep .vuestic-modal .modal-header{
//   display: none;
// }

::v-deep .main-tool .box-close {
  margin-top: 85px;
}

.container-dhh {
  @media (max-width: 767px) {
    padding: 0px !important;
  }
}

::v-deep .petModal {
  .modal-dialog {
    @media (max-width: 767px) {
      width: 100% !important;
      height: 100vh;
      margin: 0;
      margin-top: 0 !important;
      border-radius: 0px !important;

      .modal-content {
        border-radius: 0px !important;
        min-height: 100vh;
      }
      .close-modal {
        color: white;
        top: 5px;
        right: auto;
        left: 0px;
      }
    }
  }

  .pet-avatar {
    @media (max-width: 767px) {
      display: none;
    }
  }

  .pet-selectors {
    @media (max-width: 767px) {
      width: 100% !important;
    }
  }
}

::v-deep .layoutModal {
  h1 {
    text-align: center;
    font-size: 26px;
    color: #68596e !important;
    margin-bottom: 0px;

    // span{
    //   color: #907feb !important;
    // }
  }

  p {
    text-align: center;
    font-size: 14px;
    padding-top: 0px;
    color: #68596e;
  }

  .modal-dialog {
    @media (max-width: 767px) {
      margin: 0px;
      width: 100% !important;
      margin-top: 0px !important;
      border-radius: 0px !important;
    }
  }

  .modal-content {
    @media (max-width: 767px) {
      border-radius: 0px !important;
    }
  }
}

.modal-footer {
  border-top: 1px solid #afa2ba;
  margin-top: 15px;
  padding-top: 15px !important;
  padding-bottom: 0px !important;
  padding-left: 0px !important;

  .cancel {
    background-color: #dfdae4;
    color: white;
  }

  &.name {
    border-top: none;
  }
}

.layout-option {
  background: #ffffff;
  border-radius: 10px;
  border: 2px solid transparent;
  margin-top: 5px;
  margin-bottom: 5px;
  // overflow:hidden;
  cursor: pointer;
  position: relative;

  -webkit-box-shadow: -1px 2px 22px -14px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px 2px 22px -14px rgba(0, 0, 0, 0.75);
  box-shadow: -1px 2px 22px -14px rgba(0, 0, 0, 0.75);

  &.active {
    border: 2px solid #01dfa1;

    .layout-option-check {
      position: absolute;
      width: 20px;
      height: 20px;
      background-color: #02dfa1;
      left: 0px;
      right: 0px;
      margin-left: auto;
      margin-right: auto;
      display: block;
      z-index: 2;
      border-radius: 10px;
      font-size: 10px;
      top: -9px;
    }

    .layout-option-check::after {
      content: "";
      position: absolute;
      left: 5px;
      top: 10px;
      background: #fff;
      width: 2px;
      height: 2px;
      -webkit-box-shadow: 2px 0 0 #fff, 4px 0 0 #fff, 4px -2px 0 #fff,
        4px -4px 0 #fff, 4px -6px 0 #fff, 4px -8px 0 #fff;
      box-shadow: 2px 0 0 #fff, 4px 0 0 #fff, 4px -2px 0 #fff, 4px -4px 0 #fff,
        4px -6px 0 #fff, 4px -8px 0 #fff;
      -webkit-transform: rotate(45deg) scale(1.6);
      transform: rotate(45deg) scale(1.6);
      -webkit-transition: -webkit-box-shadow 200ms ease;
      transition: -webkit-box-shadow 200ms ease;
      transition: box-shadow 200ms ease;
      transition: box-shadow 200ms ease, -webkit-box-shadow 200ms ease;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  .layout-option-image-wrapper {
    //height: 189px;
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    display: block;
    position: relative;

    .congrats-mobile {
      display: none;
    }

    @media (max-width: 990px) {
      .congrats {
        display: none;
      }
      .congrats-mobile {
        display: block;
      }
    }

    img {
      width: 100%;
    }
  }
}

.layout-option-footer {
  text-align: center;
  padding-top: 10px;
  font-size: 12px;
  line-height: 15px;
  margin-bottom: 9px;
  padding-top: 7px !important;

  p {
    padding-top: 7px !important;
  }
}

.testimonialModal {
  text-align: left;

  .modal-dialog {
    max-width: 500px !important;
  }

  h1 {
    text-align: left;
    font-size: 26px;
    color: #68596e;
    margin-bottom: 0px;

    span {
      color: #907feb !important;
    }
  }

  p {
    text-align: left;
    font-size: 14px;
    padding-top: 0px;
    color: #68596e;
  }

  label {
    display: block;
    width: 100%;
    color: #afa2ba !important;
    font-size: 12px;
    margin-bottom: 0px !important;
    text-align: left !important;
  }

  textarea {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
    padding-bottom: 22px;
    margin-bottom: 0;
    color: #68596e;
    border: solid 2px #afa2ba;
    resize: none;
    font-weight: 600;
    border-radius: 4px;
    height: 25vh;
    max-height: 300px;
    min-height: 100px;
    caret-color: #01dfa1;
    line-height: 1.2;
    font-size: 16px;
    background-color: white;
    text-transform: uppercase;
    width: 100%;
  }
}

.nameModal {
  text-align: left;

  .modal-dialog {
    max-width: 500px !important;
  }

  h1 {
    text-align: left;
    font-size: 26px;
    color: #68596e;
  }

  label {
    display: block;
    width: 100%;
    color: #afa2ba !important;
    font-size: 12px;
    margin-bottom: 0px !important;
    text-align: left !important;
  }

  input {
    display: block;
    background: none;
    padding: 0.125rem 0.125rem 0.0625rem;
    font-size: 1rem;
    border-width: 0;
    border-color: transparent;
    line-height: 1.9;
    width: 100%;
    // color: transparent;
    transition: all 0.28s ease;
    box-shadow: none;
    border-bottom: 1px solid #afa2ba;
    margin-bottom: 15px;
    outline: none;
  }
}

.custom-properties {
  max-width: 240px;
  position: absolute;
  right: 60px;
  background-color: #fff;
  overflow: hidden;
  top: 120px;
  text-align: left;
  box-shadow: 0 10px 20px 0 #dfdae4;
  border-radius: 10px;
  padding-bottom: 15px;
  z-index: 9;

  @media (max-width: 990px) {
    max-width: 100%;
    width: 100%;
    left: 0;
    bottom: 0px;
    top: auto;
    border-radius: 0px;
    position: relative;
  }

  b {
    color: #afa2ba !important;
    font-family: "Nunito";
    font-size: 14px;
    padding: 0px 15px;
  }

  .itens-properties {
    border-top: 2px solid #50e3c2;
    padding: 0px 25px !important;
    padding-top: 10px !important;
    z-index: 10;

    label {
      display: block;
      width: 100%;

      color: #afa2ba !important;
      font-size: 12px;
      margin-bottom: 0px !important;
    }

    span {
      display: block;
      width: 100%;
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
      font-size: 14px;
      color: #68596e;
    }

    hr {
      margin: 0 !important;
      margin-bottom: 10px !important;
      margin-top: 10px !important;
    }

    .flex {
      cursor: pointer;
      position: relative;

      svg {
        position: absolute;
        right: 5px;
        top: 12px;
      }
    }
  }
}
.congrats {
  width: auto;
}
.header-item {
  .btn {
    position: absolute;
    right: 15px;
    top: 15px;
    padding: 20px 30px;
    border-radius: 40px;

    @media (max-width: 990px) {
      position: absolute;
      right: 15px;
      top: 15px;
      top: 7px;
      padding: 8px 12px;
    }
  }
}
.show-book {
  opacity: 1 !important;
  height: auto;
}
.hide-book {
  opacity: 0 !important;
  height: 0px;
  overflow-y: hidden;
}
.loading-box {
  position: absolute;
  left: 0;
  right: 0;
  top: 35%;
  bottom: 0;
  margin-bottom: auto;
  margin-top: auto;
  z-index: 9999;

  small {
    width: 100%;
    font-size: 14px;
    font-weight: bold;
    display: block;
    font-family: "Nunito";
    margin-top: 20px;
  }

  h4 {
    font-size: 18px;
    margin-top: 10px;
    font-weight: 400;
  }
}

@keyframes shake {
  0% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
  10% {
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
  }
  20% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}

@-webkit-keyframes shake {
  0% {
    -webkit-transform: translateX(0px);
  }
  40% {
    -webkit-transform: translateX(20px);
  }
  50% {
    -webkit-transform: translateX(0px);
  }
  60% {
    -webkit-transform: translateX(20px);
  }
  100% {
    -webkit-transform: translateX(0px);
  }
}

.container-book {
  transition: margin 700ms;
}

.close {
  a {
    position: absolute;
    top: 26px;
    color: #4965bc;
    opacity: 1;

    // svg{
    //     path{
    //       fill:#4965bc;
    //       stroke:#4965bc;
    //     }
    //   }

    &:hover {
      svg {
        path {
          fill: #4965bc;
          stroke: #4965bc;
        }
      }
    }

    @media (max-width: 990px) {
      top: 7px;
      left: 15px;
    }
  }
}

.header-menu {
  padding-bottom: 8px !important;
  border-bottom: 1px solid #dfdae4;
  margin-bottom: 0px !important;
  overflow: hidden;

  @media (max-width: 990px) {
    padding-bottom: 0px !important;
    min-height: 50px;
    // padding-bottom: 10px !important;
  }

  h1 {
    font-size: 24px;
    margin-bottom: 20px;
    margin-top: 30px;
    color: #68596e !important;

    @media (max-width: 990px) {
      font-size: 20px;
      margin-top: 20px;
      margin-bottom: 0px;
      display: none;
    }
  }

  .back-circle {
    margin-top: 20px;
    display: block;

    @media (max-width: 990px) {
      margin-top: 0px;
    }
  }
}

.list-mobile {
  width: 100%;
  overflow-x: scroll;
  text-align: left;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;

  .mobile-shelf {
    margin-left: 20px;
  }

  &.active {
    -webkit-animation: shake 1.6s ease-in-out infinite;
    animation: shake 1.6s ease-in-out infinite;
  }
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
}

.mini-cover {
  display: inline-block;
  max-width: 312px;
  position: relative;
  width: 100%;
  text-align: left;

  margin: 0px 5px;

  &:first-child {
    margin-right: 5px;
  }

  &:nth-child(odd) {
    margin-left: 0;

    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background-image: -webkit-gradient(
        linear,
        left top,
        right top,
        from(black),
        color-stop(7%, transparent)
      );
      background-image: linear-gradient(to right, black, transparent 7%);
      opacity: 0.15;
    }
  }
  &:nth-child(even) {
    margin-right: 0;

    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background-image: -webkit-gradient(
        linear,
        right top,
        left top,
        from(black),
        color-stop(7%, transparent)
      );
      background-image: linear-gradient(to left, black, transparent 7%);
      opacity: 0.15;
    }
  }

  img {
    width: 100%;
    filter: none !important;
    -webkit-filter: none !important;
    max-height: 437px;
  }
}

body {
  overflow-x: hidden !important;
}
.controls {
  margin: 0 auto;
  display: block;
  display: inline-block;
  margin-top: 20px;
  position: relative;
  z-index: 2;

  button {
    display: inline-block;
    background-color: #ffffff;
    -webkit-box-shadow: 0px 3px 15px -8px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 3px 15px -8px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 3px 15px -8px rgba(0, 0, 0, 0.75);
    height: 47px;
    width: 47px;
    border-radius: 30px;
    text-align: center;
    color: #afa2ba;
    border: none;
  }

  .info-space {
    display: inline-block;
    margin: 0px 12px;
    font-size: 10px;
    font-weight: 900;
    color: #afa2ba;
    font-family: "Nunito", "Helvetica Neue", Helvetica, Arial, sans-serif;
    position: relative;
    top: 9px;
  }

  .current-pages {
    width: 60px;
    white-space: nowrap;
    line-height: 1;
    overflow: hidden;
    transition: all 0.5s ease;

    .list {
      position: relative;
      transition: all 0.5s ease;
    }

    span {
      width: 30px;
      display: inline-block;
      font-size: 16px;
      text-align: center;

      &:first-child {
        margin: 0px 15px;
      }

      &:last-child {
        margin: 0px 15px;
      }
    }
  }
}

.loading-bg {
  background-color: #fff;
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  opacity: 0.65;
  filter: blur(8px);
  -webkit-filter: blur(0px);
}
img {
  width: 100%;
  /* Add the blur effect */
}

::v-deep {
  .bounding-box {
    cursor: grab !important;
  }
}

.action-bar {
  position: absolute;
  bottom: 0px;
  text-align: center;
  left: 0;
  right: 0;
}

::v-deep.container {
  max-width: 100% !important;

  @media (min-width: 1200px) {
    max-width: 100% !important;
  }

  @media (min-width: 992px) {
    max-width: 100% !important;
  }

  @media (min-width: 768px) {
    max-width: 100% !important;
  }

  @media (min-width: 576px) {
    max-width: 100% !important;
  }
}

.my-account {
  position: absolute;
  top: 0px;
  background-color: #f8f7fc;
  z-index: 3;
  width: 100%;
  padding-bottom: 30px;
  min-height: 100%;
  overflow: hidden;
  // height: 100%;
}
.flipbook {
  width: 90vw;
  height: 90vh;
  position: relative;
}

body {
  position: relative;
  display: flex;
  padding: 0;
  margin: 0;
  min-height: 100vh;
}
.book {
  height: 40vh;
  width: 90vh;
  margin: auto;
}
.book img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

/* PET */
.pet-view {
  width: 100%;
  display: flex;
}

.pet-avatar {
  transition: all 1s ease;
}

.pet-scroll {
  @media (max-width: 767px) {
    overflow-x: scroll;
  }
}
.pet-list {
  width: 100%;
  display: flex;

  // justify-content: space-between;

  @media (max-width: 767px) {
    min-width: 1350px;

    margin-bottom: 20px;
  }

  div {
    margin: px-size("lg", 5px);
    cursor: pointer;
    transition: all 1s ease;

    @media (max-width: 767px) {
      border: 1px solid #dfdae4;
      border-radius: 10px;
      margin-right: 15px;
    }

    &.inactive {
      opacity: 0.5;
    }
  }
  img {
    width: 100%;
  }
}

.color-list {
  width: 100%;
  display: flex;
}

.color {
  height: 40px;
  width: 40px;
  display: block;
  border-radius: 100%;
  margin-left: 6px;
  margin-right: 6px;
  padding: 3px;
  border: 2px solid #efefef;
  -webkit-transition: border-color 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  transition: border-color 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  overflow: hidden;
  cursor: pointer;

  &.is-selected {
    border-color: #01dfa1;
  }

  .secondary-color {
    width: 50%;
    position: absolute;
    right: 0px;
    height: 100%;
    top: 0px;
  }
}

.footer-pet {
  width: 100%;
  display: flex;

  .cancel {
    margin-left: 20px;
    background-color: none;
    background-image: none;
    box-shadow: none;
    padding: 0px;
    border-radius: 0px;
    color: #68596e;
    background: transparent;
  }
}

::v-deep .colored {
  background-color: #bf5189;
  height: auto;
  padding-top: 30px;
  text-align: center;

  .modal-title {
    width: 100%;
  }

  h1 {
    color: #fff;
    margin-bottom: 5px;

    @media (max-width: 767px) {
      font-size: 18px;
    }
  }

  p {
    color: #fff;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    font-family: "Nunito";
  }
}
.header-pet h1 {
  background-color: #bf5189;
}

::v-deep .vuestic-modal .close-modal {
  position: absolute;
  right: 36px;
  top: 16px;
  width: 10px;
  height: 10px;
  display: block;
  color: #dfdad4;
}

.modal-avatar {
  width: 100%;
  position: relative;
  max-height: 280px;
  height: 100vh;
  border: 1px solid #dfdae4;
  border-radius: 10px;
  text-align: center;
  margin-bottom: 10px;

  img {
    position: absolute;
    left: 7px;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 100%;

    @media (max-width: 480px) {
      max-width: 50%;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
    }
  }

  // @media(max-width: 480px){
  //     max-height: 450px;
  // }

  // @include media-breakpoint-down('sm') {
  //   max-height: 230px;
  //   height: 100vh;
  // }

  // @media(max-width: 990px){
  //   flex: 0 0 100%;
  // }
}

.modal-avatar-list {
  .modal-actions {
    // padding: 25px 30px;
    border-top: 1px solid #dfdae4;
    display: flex;
    justify-content: flex-end;

    .btn {
      flex: 1;
      border: 0;
      &:hover {
        color: #34495e;
      }
    }
    .btn-todos {
      margin-right: 5px;
      background: white;
      border: 1px solid #dfdae4;
      color: #4965bc;
    }
    .btn-esse {
      margin-left: 5px;
    }
  }

  .modal-footer {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding: 0px;
    padding-bottom: calc(25px - 20px);
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-bottom: 15px;
  }

  .modal-content {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 25px;
    padding-bottom: 0px;
  }

  ::v-deep .action-area {
    padding: 16px 16px;
    .btn-right {
      margin-left: 8px;
    }
  }

  ::v-deep .modal-title {
    color: rgb(22, 211, 219);
    font-family: Nunito, sans-serif;
    font-weight: 900;
    line-height: 1.5;
    padding-top: 4px;
  }

  ::v-deep .modal-body {
    padding: 0px !important;
    padding-bottom: 0px;

    .va-row {
      padding: 16px 24px;
      // padding-bottom: 0px;
    }

    ::v-deep hr {
      margin-top: 0px;
    }

    .modal-text {
      display: flex;
      align-items: center;
    }
  }

  // ::v-deep .modal-header {
  // 	height: 55px;
  // 	padding: 0px;
  // 	border-bottom: none;
  // 	font-size: 1.2rem;
  // 	display: -webkit-box;
  // 	display: -ms-flexbox;
  // 	display: flex;
  // 	-webkit-box-align: center;
  // 	-ms-flex-align: center;
  // 	align-items: center;

  // 	//display: none !important;
  // }

  .footer-modal {
    margin-bottom: 24px;

    .right {
      float: none;
    }
  }
}

.back-area {
  display: flex;
  align-items: center;
}
</style>
